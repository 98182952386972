import droits from '../../auth/droits';

const FORMAT_LISTE = { value: '1', text: 'Listes' };
const FORMAT_EXCEL = { value: '3', text: 'Excel' };
const FORMAT_ZIP = { value: '4', text: 'Zip' };


export default [
  {
    id: 1,
    name: 'Adhérents par CL principal',
    droit: droits.IMPRESSION_ADHERENT_PAR_CPE_PRINCIPAL,
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 2,
    droit: droits.IMPRESSION_ADHERENT_PAR_CPE_APPARTENANCE,
    name: "Adhérents par Conseil Local d'appartenance",
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 3,
    droit: droits.IMPRESSION_ADHERENTS_PAR_ETABLISSEMENT_CLASSE,
    name: 'Adhérents par établissement et classe',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showEtablissement: true,
  },
  {
    id: 4,
    droit: droits.IMPRESSION_ABONNES_PAR_CPE_PRINCIPAL,
    name: 'Abonnés par conseil local principal',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  // {
  //   id: 5,
  //   droit: droits.IMPRESSION_ABONNES_ACTIFS_REVUE_DEPARTEMENTALE,
  //   name: 'Abonnés actifs à la revue départementale',
  // },
  {
    id: 7,
    droit: droits.IMPRESSION_ANCIENS_ADHERENTS,
    name: 'Anciens adhérents',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 8,
    droit: droits.IMPRESSION_RESPONSABLES_DEPARTEMENTAUX,
    name: 'Responsables départementaux',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showResponsabilites: true,
    typeResponsabilite: 'departement',
  },
  // {
  //   id: 9,
  //   droit: droits.IMPRESSION_DELEGUES_DEPARTEMENTAUX,
  //   name: 'Délégués départementaux',
  // },
  {
    id: 10,
    droit: droits.IMPRESSION_RESPONSABLES_CPE,
    name: 'Responsables conseil locaux',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
    showResponsabilites: true,
    typeResponsabilite: 'local',
  },
  // {
  //   id: 11,
  //   droit: droits.IMPRESSION_DELEGUES_ETABLISSEMENT,
  //   name: 'Délégués d\'établissement',
  // },
  {
    id: 12,
    droit: droits.IMPRESSION_CPE,
    name: 'Conseils locaux',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 24,
    droit: droits.IMPRESSION_EXTRACTION_EMAIL_NEWSLETTER,
    name: 'Extraction emails des parents inscrits à la newsletter',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    cdpeRequired: false,
    showExtractSince: true,
  },
  {
    id: 13,
    droit: droits.IMPRESSION_FICHE_SIGNALETIQUE_CPE,
    name: 'Fiche signalétique conseils locaux',
    formats: [FORMAT_LISTE],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 14,
    droit: droits.IMPRESSION_RAPPROCHEMENT_COMPTABLE_GLOBAL,
    name: 'Rapprochement comptable global',
    formats: [FORMAT_LISTE],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 15,
    droit: droits.IMPRESSION_RAPPROCHEMENT_COMPTABLE_ADHESIONS_PAR_CPE,
    name:
      'Rapprochement comptable des adhésions par conseil local et par niveau',
    formats: [FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 16,
    droit: droits.IMPRESSION_RAPPROCHEMENT_COMPTABLE_ABONNEMENTS_PAR_CPE,
    name: 'Rapprochement comptable des abonnements par conseil local',
    formats: [FORMAT_LISTE],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 25,
    droit: droits.IMPRESSION_RAPPROCHEMENT_COMPTABLE_DONS_PAR_CPE,
    name: 'Rapprochement comptable des dons par conseil local',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCommune: true,
    showDegreEnseignement: true,
    showSecteurGeographique: true,
  },
  {
    id: 18,
    droit: droits.IMPRESSION_SAISIES_DES_FOYERS,
    name: 'Saisies des parents',
    formats: [FORMAT_LISTE],
    showCdpe: true,
    showExtractSince: true,
  },
  {
    id: 17,
    droit: droits.IMPRESSION_SUIVI_STATISTIQUE,
    name: 'Suivi statistique',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showAnneesScolaires: true,
    showCdpe: true,
  },
  {
    id: 20,
    droit: droits.IMPRESSION_FOYERS_VALIDES_PAR_DEPARTEMENT,
    name: 'Adhérents et abonnées validés par département',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
  },
  {
    id: 22,
    droit: droits.IMPRESSION_FOYERS_NONVALIDES_PAR_DEPARTEMENT,
    name: 'Adhérents et abonnées non validés par département',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
  },
  // {
  //   id: 23,
  //   droit: droits.IMPRESSION_RESPONSABILITE_ANNEE_COURANTE_MANQUANTE,
  //   name: 'Responsabilités de l\'année en cours',
  // },
  {
    id: 26,
    droit: droits.IMPRESSION_EXTRACTION_RECUS_DONS,
    name: 'Extraction des reçus fiscaux des parents',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showCalendarYears: true,
  },
  {
    id: 27,
    droit: droits.IMPRESSION_CDPE,
    name: 'Fiche conseil départemental',
    formats: [FORMAT_LISTE],
    showCdpe: true,
    cdpeRequired: false,
  },
  {
    id: 28,
    droit: droits.IMPRESSION_CDPE,
    name: 'Liste conseils départementaux',
    formats: [FORMAT_EXCEL],
  },
  {
    id: 29,
    droit: droits.IMPRESSION_CDPE,
    name: 'Liste présidents de CDPE',
    formats: [FORMAT_EXCEL],
  },
  {
    id: 30,
    droit: droits.IMPRESSION_CDPE,
    name: 'Liste trésoriers de CDPE',
    formats: [FORMAT_EXCEL],
  },
  {
    id: 31,
    droit: droits.IMPRESSION_ADHESIONS_EN_LIGNE,
    name: 'Adhésions en ligne',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showAnneesScolairesSaisies: true,
    showExtractSince: true,
  },
  {
    id: 32,
    droit: droits.IMPRESSION_SUIVI_ADHESIONS_EN_LIGNE,
    name: 'Suivi adhésion en ligne par Conseil Local',
    formats: [FORMAT_LISTE],
    showCdpe: true,
    showAnneesScolairesSaisies: true,
    showExtractSince: true,
  },
  {
    id: 33,
    droit: droits.IMPRESSION_ADHESIONS_EN_LIGNE_MISE_EN_ATTENTE,
    name: 'Adhésions en ligne mises en attente',
    formats: [FORMAT_LISTE, FORMAT_EXCEL],
    showCdpe: true,
    showAnneesScolairesSaisies: true,
    showExtractSince: true,
  },
  {
    id: 34,
    droit: droits.IMPRESSION_ADHESIONS_ABONNEMENTS_CL,
    name: 'Informations complètes adhérents',
    formats: [FORMAT_EXCEL],
    showCdpe: true,
    showConseilLocal: true,
    showExtractSince: true,
    cdpeRequired: false,
  },
  {
    id: 35,
    droit: droits.IMPRESSION_RECUS_FISCAUX,
    name: 'Informations reçus fiscaux par département',
    formats: [FORMAT_EXCEL],
    showCalendarYears: true,
    showCdpe: true,
    cdpeRequired: false,
  },
  {
    id: 36,
    droit: droits.IMPRESSION_CDPE,
    name: 'Archives',
    formats: [FORMAT_ZIP],
    showCdpe: true,
    cdpeRequired: false,
    showAnneesScolaires: true,
  },
];
