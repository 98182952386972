<template>
  <ImpressionsForm />
</template>


<script>
import ImpressionsForm from '../../components/impressions/ImpressionsForm';

export default {
  name: 'ImpressionsContainer',
  components: {
    ImpressionsForm,
  },
};
</script>
