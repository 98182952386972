<template>
  <v-card>
    <div class="pa-5 mb-5">
      <form
        ref="form"
        @submit.prevent="validate"
      >
        <v-card-title
          primary-title
          class="title"
        >
          Impressions Prédéfinies
        </v-card-title>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              :items="impressions"
              item-text="name"
              label="Type d'impression *"
              return-object
              @change="setCurrentImpression"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="currentFormat"
              :items="formats"
              label="Format *"
              @change="setCurrentFormat"
            />
          </v-col>
        </v-row>

        <div v-if="currentImpression">
          <v-row v-if="showCdpe || showConseilLocal">
            <v-col
              v-if="showCdpe"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentCdpeId"
                :items="getFilterDepartements"
                :item-text="item => `${item.code} - ${item.nom}`"
                item-value="id"
                :label="`Département${cdpeRequired?' *':''}`"
                clearable
                :required="cdpeRequired"
                @change="setCurrentCdpeId"
              />
            </v-col>
            <v-col
              v-if="showConseilLocal"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentConseilLocalId"
                :items="getFilterConseilsLocaux"
                :item-text="item => `${item.code} - ${item.nom}`"
                item-value="id"
                label="Conseil Local"
                clearable
                @change="setCurrentConseilLocalId"
              />
            </v-col>
          </v-row>
          <v-row v-if="showCommune">
            <v-col
              cols="12"
              md="6"
            >
              <CommuneAutocomplete
                :loaded-commune="currentCommuneConseil"
                :perimetre-id-filter="currentCdpeId"
                :required="false"
                field-name="Code Postal / Commune du conseil local"
                :disabled="currentConseilLocalId>0"
                @changed="setCurrentCommuneConseil"
              />
            </v-col>
          </v-row>
          <v-row v-if="showDegreEnseignement || showSecteurGeographique">
            <v-col
              v-if="showDegreEnseignement"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentDegreEnseignementId"
                :items="filterDegresEnseignement"
                item-text="libelle"
                item-value="id"
                label="Degré enseignement"
                clearable
                :disabled="currentConseilLocalId>0"
              />
            </v-col>
            <v-col
              v-if="showSecteurGeographique"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentSecteurGeographiqueId"
                :items="filterSecteursGeographique"
                :item-text="renderSecteurGeographiqueItem"
                item-value="id"
                label="Secteur géographique"
                clearable
                :disabled="currentConseilLocalId>0"
              />
            </v-col>
          </v-row>
          <v-row v-if="showEtablissement">
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentEtablissementId"
                :items="filterEtablissement"
                :item-text="(item) => `${item.nom} (${item.code_postal} ${item.ville})`"
                item-value="id"
                label="Etablissements"
                clearable
                @change="setCurrentEtablissementId"
              />
            </v-col>
          </v-row>
          <v-row v-if="showResponsabilites">
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentResponsabiliteId"
                :items="filterResponsabilites"
                item-text="nom"
                item-value="id"
                label="Responsabilites"
                clearable
              />
            </v-col>
          </v-row>
          <v-row v-if="showAnneesScolairesSaisies || showAnneesScolaires">
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentAnneeScolaireId"
                :items="showAnneesScolairesSaisies ? anneesScolairesSaisies : anneesScolaires"
                item-text="libelle"
                item-value="id"
                label="Année Scolaire *"
                :required="true"
              />
            </v-col>
          </v-row>
          <v-row v-if="showPeriode">
            <v-col
              cols="12"
              md="6"
            >
              <DatePicker
                label="Date début"
                :readonly="true"
                :required="true"
                @changeDate="changeCurrentPeriodeFrom"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <DatePicker
                label="Date fin"
                :readonly="true"
                :required="true"
                @changeDate="changeCurrentPeriodeTo"
              />
            </v-col>
          </v-row>
          <v-row v-if="showExtractSince">
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="currentTypeExtractionId"
                :items="filterExtraction"
                label="Extraire *"
                clearable
                required="true"
              />
            </v-col>
            <v-col
              v-if="showExtractPeriode"
              cols="4"
            >
              <DatePicker
                label="Depuis le"
                :readonly="true"
                :required="true"
                @changeDate="changeExtractPeriodFrom"
              />
            </v-col>
            <v-col
              v-if="showExtractPeriode"
              cols="4"
            >
              <DatePicker
                label="Jusqu'au"
                :readonly="true"
                :required="true"
                @changeDate="changeExtractPeriodTo"
              />
            </v-col>
          </v-row>
          <v-row v-if="showCalendarYears">
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="currentSelectedCalendarYear"
                :items="filterCalendarYears"
                label="Année Civile *"
                clearable
                required="true"
              />
            </v-col>
          </v-row>
          <!-- TODO : Filtre Délégations Départementales -->
          <!-- TODO : Filtre Délégations d'établissements -->
          <v-btn
            color="primary"
            :disabled="!canPrint"
            @click="print"
          >
            Générer
          </v-btn>
          <Downloader
            :downloading-file="downloadingFile"
            :label="downloadingFileLabel"
          />
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CommuneAutocomplete from '../partials/CommuneAutocomplete';
import DatePicker from '../partials/DatePicker';
import Downloader from '../partials/Downloader';
import impressionsConfig from './impressionsConfig';

const EXTRACT_SINCE_PERIOD = 3;

// Possibilité de sélectionner l'année N-1 qu'à partir du 01/04/N à 00:00
const currentDate = new Date();
const limitDate = new Date(currentDate.getFullYear(), 3, 1);  
const FIRST_SELECTABLE_YEAR = currentDate >  limitDate ? currentDate.getFullYear() - 1 : currentDate.getFullYear() - 2;

export default {
  name: 'ImpressionsForm',
  components: {
    CommuneAutocomplete,
    DatePicker,
    Downloader,
  },
  props: {
  },
  data: () => ({
    impressions: [],
    currentImpression: null,
    currentFormat: null,
    currentCdpeId: 0,
    currentConseilLocalId: 0,
    currentCommuneConseil: null,
    currentDegreEnseignementId: 0,
    currentSecteurGeographiqueId: 0,
    currentEtablissementId: 0,
    currentResponsabiliteId: 0,
    currentAnneeScolaireId: 0,
    currentPeriodeFrom: null,
    currentPeriodeTo: null,
    currentTypeExtractionId: 0,
    extractPeriodFrom: null,
    extractPeriodTo: null,
    downloadingFile: null,
    downloadingFileLabel: '',
    nbImpression: 0,
    filterExtraction:[
      { value: 1, text: 'Depuis la dernière extraction' },
      { value: 2, text: 'Depuis le debut de l\'année scolaire' },
      { value: EXTRACT_SINCE_PERIOD, text: 'Dans la période définie' },
    ],
    currentSelectedCalendarYear: FIRST_SELECTABLE_YEAR,
    filterCalendarYears:[
      FIRST_SELECTABLE_YEAR,
      FIRST_SELECTABLE_YEAR - 1,
    ],
  }),
  computed: {
    ...mapGetters('session', [
      'isUserLocal',
      'isUserDepartemental',
      'isUserNational',
      'getPerimetreUser',
      'getDroits',
    ]),
    ...mapGetters('cdpe', [
      'getFilterDepartements',
    ]),
    ...mapGetters('conseilLocal', [
      'getFilterConseilsLocaux',
    ]),
    ...mapState('conseilLocal', {
      filterDegresEnseignement: state => state.currentDegresEnseignement,
      filterSecteursGeographique: state => state.currentSecteursGeographique,
    }),
    ...mapState('conseilLocal/etablissements', {
      etablissementsForCurrentCdpe: state => state.etablissementsForCurrentCdpe,
    }),
    ...mapState('responsabilites', {
      responsabilitesLocal: state => state.responsabilitesLocal,
      responsabilitesDepartemental: state => state.responsabilitesDepartemental,
    }),
    ...mapGetters('parametrages/anneeScolaire', {
        anneesScolairesSaisies: 'listAnneeScolaireSaisie',
        anneesScolaires: 'getAnneesScolaires',
        anneeScolaireCourante: 'getAnneeScolaireCourante',
    }),
    formats() {
      return this.currentImpression?this.currentImpression.formats:[];
    },
    showCdpe() {
      return this.currentImpression?
        this.currentImpression.showCdpe && this.isUserNational
        :false;
    },
    cdpeRequired() {
      return this.currentImpression?
        this.currentImpression.cdpeRequired && this.isUserNational
        :false;
    },
    showConseilLocal() {
      return this.currentImpression?
        this.currentImpression.showConseilLocal && !this.isUserLocal
        :false;
    },
    showCommune() {
      return this.currentImpression?
        this.currentImpression.showCommune
        :false;
    },
    showDegreEnseignement() {
      return this.currentImpression?
        this.currentImpression.showDegreEnseignement
        :false;
    },
    showSecteurGeographique() {
      return this.currentImpression?
        this.currentImpression.showSecteurGeographique
        :false;
    },
    showEtablissement() {
      return this.currentImpression?this.currentImpression.showEtablissement:false;
    },
    filterEtablissement() {
      return this.etablissementsForCurrentCdpe;
    },
    showResponsabilites() {
      return this.currentImpression?this.currentImpression.showResponsabilites:false;
    },
    filterResponsabilites() {
      if (this.currentImpression) {
        if (this.currentImpression.typeResponsabilite === 'local') {
          return this.responsabilitesLocal[this.currentCdpeId];
        } else {
          return this.responsabilitesDepartemental;
        }
      }
      return [];
    },
    showAnneesScolairesSaisies() {
      return this.currentImpression?this.currentImpression.showAnneesScolairesSaisies:false;
    },
    showAnneesScolaires() {
      return this.currentImpression?this.currentImpression.showAnneesScolaires:false;
    },
    showPeriode() {
      return this.currentImpression?this.currentImpression.showPeriode:false;
    },
    showExtractSince() {
      return this.currentImpression?this.currentImpression.showExtractSince:false;
    },
    showExtractPeriode() {
      return this.currentTypeExtractionId === EXTRACT_SINCE_PERIOD;
    },
    showCalendarYears() {
      return this.currentImpression?this.currentImpression.showCalendarYears:false;
    },
    canPrint() {
      if (!this.currentImpression) {
        return false;
      }
      if (!this.currentFormat) {
        return false;
      }
      if (!this.currentCdpeId && this.showCdpe && this.cdpeRequired) {
        return false;
      }
      if (!this.currentTypeExtractionId && this.showExtractSince) {
        return false;
      }
      if (this.currentTypeExtractionId === EXTRACT_SINCE_PERIOD) {
        if (!this.extractPeriodFrom || !this.extractPeriodTo) {
          return false;
        }
      }
      if (this.showPeriode && (!this.currentPeriodeFrom || !this.currentPeriodeTo)) {
          return false;
      }
      return true;
    },
  },
  watch: {
    formats(formats) {
      if (formats.length === 1) {
        this.setCurrentFormat(formats[0].value);
      }
    },
  },
  created() {
    let defaultImpressionConfig = {
      showCdpe: false,
      cdpeRequired: true,
      showConseilLocal: false,
      showCommune: false,
      showDegreEnseignement: false,
      showSecteurGeographique: false,
      showEtablissement: false,
      showAnneesScolairesSaisies: false,
      showAnneesScolaires: false,
      showPeriode: false,
      showExtractSince: false,
      showCalendarYears: false,
    };
    this.impressions = impressionsConfig
        .filter((impression) => this.getDroits.includes(impression.droit))
        .map((impression) => ({ ...defaultImpressionConfig, ...impression }));

    this.initCurrentCdpeConseil();

    this.loadFilterAnneesScolaires();

    if (this.isUserNational) {
      this.loadFilterDepartements();
    }
    if (this.isUserDepartemental) {
      let cdpeId = this.getPerimetreUser.perimetre_id;
      this.loadFiltersForCdpe(cdpeId);
    }

  },
  methods: {
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
    ...mapActions('conseilLocal', [
      'loadFilterConseilsLocaux',
      'loadDegreEnseignement',
      'loadSecteurGeographique',
    ]),
    ...mapActions('conseilLocal/etablissements', [
      'loadEtablissementsByCdpeId',
    ]),
    ...mapActions('responsabilites', [
      'loadResponsabilitesLocal',
      'loadResponsabilitesDepartemental',
    ]),
    ...mapActions('parametrages/anneeScolaire', {
        loadFilterAnneesScolaires: 'loadFilterAnneesScolaires',
    }),
    initCurrentCdpeConseil() {
      if (this.isUserLocal) {
        this.currentCdpeId = this.getPerimetreUser.perimetre_appartenance_id;
        this.currentConseilLocalId = this.getPerimetreUser.perimetre_id;
      } else if (this.isUserDepartemental) {
        this.currentCdpeId = this.getPerimetreUser.perimetre_id;
        this.currentConseilLocalId = 0;
      } else {
        this.currentCdpeId = 0;
        this.currentConseilLocalId = 0;
      }
    },
    setCurrentImpression(impression) {
      this.currentImpression = impression;
      this.currentFormat = null;
      this.initCurrentCdpeConseil();
      this.loadFiltersForCdpe();
      this.currentCommuneConseil = null;
      this.currentDegreEnseignementId = 0;
      this.currentSecteurGeographiqueId = 0;
      this.currentEtablissementId = 0;
      this.currentResponsabiliteId = 0;
      this.currentAnneeScolaireId = this.anneeScolaireCourante.id;
      this.currentPeriodeFrom = null;
      this.currentPeriodeTo = null;
      this.currentTypeExtractionId = 0;
      this.extractPeriodFrom = null;
      this.extractPeriodTo = null;
    },
    setCurrentFormat(format) {
      this.currentFormat=format;
    },
    setCurrentCdpeId(cdpeId) {
      this.currentCdpeId=cdpeId;
      if (cdpeId) {
        this.loadFiltersForCdpe();
      }
    },
    loadFiltersForCdpe() {
      if (!this.currentCdpeId) {
        return;
      }
      if (this.showConseilLocal) {
        this.loadFilterConseilsLocaux(this.currentCdpeId);
      }
      if (this.showDegreEnseignement) {
        this.loadDegreEnseignement({ cdpeId: this.currentCdpeId });
      }
      if (this.showSecteurGeographique) {
        this.loadSecteurGeographique({ cdpeId: this.currentCdpeId });
      }
      if (this.showResponsabilites) {
        this.loadResponsabilitesLocal(this.currentCdpeId);
        this.loadResponsabilitesDepartemental(this.currentCdpeId);
      }
      if (this.showEtablissement) {
        this.loadEtablissementsByCdpeId({ cdpeId: this.currentCdpeId });
      }
    },
    setCurrentConseilLocalId(conseilLocalId) {
      this.currentConseilLocalId=conseilLocalId;
      this.currentCommuneConseil = null;
      this.currentDegreEnseignementId = 0;
      this.currentSecteurGeographiqueId = 0;
    },
    setCurrentCommuneConseil(commune) {
      this.currentCommuneConseil=commune;
    },
    setCurrentEtablissementId(etablissementId) {
      this.currentEtablissementId=etablissementId;
    },
    changeCurrentPeriodeFrom(date) {
      this.currentPeriodeFrom = this.formatDate(date);
    },
    changeCurrentPeriodeTo(date) {
      this.currentPeriodeTo = this.formatDate(date);
    },
    changeExtractPeriodFrom(date) {
      this.extractPeriodFrom = this.formatDate(date);
    },
    changeExtractPeriodTo(date) {
      this.extractPeriodTo = this.formatDate(date);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    print() {
      let paramsUrl =  {
        type: this.currentImpression.id,
        format: this.currentFormat ? this.currentFormat : 0,
        cdpeId: this.currentCdpeId ? this.currentCdpeId : 0,
        cpeId: this.currentConseilLocalId ? this.currentConseilLocalId : 0,
        cpeCommuneId: this.currentCommuneConseil ? this.currentCommuneConseil.id : 0,
        degEnseignId: this.currentDegreEnseignementId ? this.currentDegreEnseignementId : 0,
        secteurGeogId: this.currentSecteurGeographiqueId ? this.currentSecteurGeographiqueId : 0,
        responsabId: this.currentResponsabiliteId ? this.currentResponsabiliteId : 0,
        delegationId: 0,
        etablissementId: this.currentEtablissementId ? this.currentEtablissementId : 0,
        anneeScolaireCoId: 0,
        anneeScolairePrId: 0,
        anneeScolaireId: this.currentAnneeScolaireId ? this.currentAnneeScolaireId : 0,
        dateMin: this.currentPeriodeFrom ? this.currentPeriodeFrom : this.extractPeriodFrom ? this.extractPeriodFrom : '',
        dateMax: this.currentPeriodeTo ? this.currentPeriodeTo : this.extractPeriodTo ? this.extractPeriodTo : '',
        typeExtract: this.currentTypeExtractionId ? this.currentTypeExtractionId : 0,
        calendarYear: this.currentSelectedCalendarYear ? this.currentSelectedCalendarYear: 0,
      };
      this.downloadingFile = {
        url: '/api/impression',
        params: paramsUrl,
      };
      this.downloadingFileLabel = this.currentImpression.name;
      this.nbImpression++;
    },
    renderSecteurGeographiqueItem(item) {
      return `${item.lettre}${item.libelle ? ' (' + item.libelle + ')' : ''} `;
    },
  },
};
</script>
